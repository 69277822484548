<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">庆贺！集美大学信息工程学院与历思“牵手”成功！</div>
      <p class="time">2020.08.18</p>
      <p class="space_content" >
        近日，集美大学信息工程学院和厦门历思科技有限公司（以下简称历思）正式合作共建智慧物证工程技术中心暨联合实验室（以下简称共建中心）！
      </p>
      <p class="space_content" style="font-weight: 600">
        集美大学信息工程学院
      </p>
      <p class="space_content" >
        集美大学信息工程学院成立于1999年，在学科建设、教学科研、人才培养、实验室建设等方面都取得了显著的成绩。学院始终坚持集美大学的办学指导思想，以培养和造就基础扎实、富有创新精神和较强实践能力的应用型信息工程技术人才为目标，为海西信息技术教育、信息产业发展和社会进步服务。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/31-1.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/31-2.png')">
      </div>

      <p class="space_content" style="font-weight: 600">
        厦门历思科技有限公司
      </p>
      <p class="space_content" >
        历思是一家拥有电子物证、环境物证、禁毒物证、食品物证、微量物证等多方面优势技术的综合性科技服务公司。历思旗下福建历思司法鉴所历经十多年发展，规范经营与开拓创新并重，逐步建立起一个声誉良好的司法鉴定品牌，目前已拥有按国家实验室标准建设的3100多平方米的大型物证中心、执业项目齐全（目前拥有17大项司法鉴定执业资质，基本覆盖司法鉴定各个领域）的前沿型跨区域司法鉴定服务机构。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/31-3.png')">
      </div>

      <p class="space_content" >
        如今，双方“牵手”成功了！
      </p>
      <p class="space_content" >
      双方协定，将开展智慧物证技术研发与应用的相关课题，共同研讨促进新技术产业化的进程。双方将进一步深化在技术研发、学术交流、人才培养等方面的合作。双方将充分发挥历思在物证技术、司法鉴定等领域的优势，以及集美大学信息工程学院的人才资源、教研成果等的优势，合作共建中心，共同推进“产学研用”，实现双方的共同发展。
    </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/31-4.png')">
      </div>

      <p class="space_content" >
        后记
      </p>
      <p class="space_content" >
        目前，历思与西南政法大学、厦门大学、集美大学、其他研究机构等多所高校和研究所开展了形式多样的合作，共同建了物证联合研究中心、联合实验室与教学科研实践基地。
      </p>


    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/30'"><p class="ellipsis1">快看，历思“智慧禁毒”黑科技，上电视台啦~</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/32'"><p class="ellipsis1">【攻略】条条线路通历思~</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
